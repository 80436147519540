/**
 * Hyper Template
 *
 * vendor.js
 *
 * Importa todos os scripts de terceiros para correta funcionalidade do template.
 * Não faz expose do jQuery, pois este já é importado no app.js da aplicação.
 */

// TODO: verificar se realmente é necessário importar todos o vendors.
// require('./vendor/bootstrap-datepicker.min.js');
require('./vendor/bootstrap-maxlength.min.js');
// require('./vendor/bootstrap-timepicker.min.js');
require('./vendor/bootstrap.bundle.js');
// require('./vendor/daterangepicker.js');
require('./vendor/highlight.pack.min.js');
require('./vendor/jquery.bootstrap-touchspin.min.js');
require('./vendor/jquery.bootstrap.wizard.min.js');
require('./vendor/simplebar.min.js');
// require('./vendor/metisMenu.js'); // importado em webpack.mix.js
// require('./vendor/select2.min.js'); // importado em webpack.mix.js

try {
    // require('./vendor/jquery.mask.min.js');
    require('./vendor/jquery.toast.min.js');
} catch (e) {}
